import Vue from 'vue'
import VueRouter from 'vue-router'

const clinicLoginPage = () => import('./pages/clinic/Login');
const clinicRegisterPage = () => import('./pages/clinic/register/Register');
const clinicSearchPage = () => import('./pages/clinic/register/ClinicSearch');
const clinicAddPage = () => import('./pages/clinic/register/ClinicAdd');
const clinicComfirmPage = () =>import('./pages/clinic/register/Comfirm');
const clinicMyPage = () => import('./pages/clinic/MyPage');
const clinicProfilePage = () => import('./pages/clinic/profile/Profile');
const clinicProfileCompletePage = () => import('./pages/clinic/profile/ProfileComplete');
const clinicStockPage = () => import('./pages/clinic/stock/Stock');
const clinicStockHistoryPage = () => import('./pages/clinic/stock/StockHistory');
const clinicCalendarPage = () => import('./pages/clinic/newCalendar/Calendar');
const clinicCalendarDayPage= () => import('./pages/clinic/newCalendar/CalendarDay');

const clinicLabPage = () => import('./pages/clinic/partner/ClinicLab');
const clinicLabInfoPage = () => import('./pages/clinic/partner/ClinicLabInfo');
const clinicLabSearchPage = () => import('./pages/clinic/partner/ClinicLabSearch');
const clinicLabAddPage = () => import('./pages/clinic/partner/ClinicLabAdd');


const clinicAccountPage = () => import('./pages/clinic/account/Account');
const clinicAccountEditPage = () => import('./pages/clinic/account/AccountEdit');
const clinicMetalPage = () => import('./pages/clinic/metal/Metal');
const clinicMetalInfoPage = () => import('./pages/clinic/metal/MetalInfo');
const clinicMetalRequestPage = () => import('./pages/clinic/metal/MetalRequest');
const clinicMetalCompletePage = () => import('./pages/clinic/metal/MetalComplete');
const clinicRegisterCompletePage = () => import('./pages/clinic/RegisterComplete');

const clinicCompletePage = () => import('./pages/clinic/Complete');
const clinicWithDrawPage = () => import('./pages/clinic/WithDraw');
const clinicWithDrawCompletePage = () => import('./pages/clinic/WithDrawComplete');
const clinicRecoverPage = () => import('./pages/clinic/recover/Recover');
const clinicRecoverConfirmPage = () => import('./pages/clinic/recover/RecoverConfirm');
const clinicRecoverWaitingPage = () => import('./pages/clinic/recover/RecoverWaiting');
const clinicRecoverCompletePage = () => import('./pages/clinic/recover/RecoverComplete');


const laboLoginPage = () => import('./pages/labo/Login');

const laboRegisterPage = () => import('./pages/labo/register/Register');
const laboRecoverPage = () => import('./pages/labo/recover/Recover');
const laboRecoverConfirmPage = () => import('./pages/labo/recover/RecoverConfirm');
const laboRecoverWaitingPage = () => import('./pages/labo/recover/RecoverWaiting');
const laboRecoverCompletePage = () => import('./pages/labo/recover/RecoverComplete');
const laboSearchPage = () => import('./pages/labo/register/LaboSearch');
const laboAddPage = () => import('./pages/labo/register/LaboAdd');
// const laboAddTestPage = () => import('./pages/labo/register/LaboAddTest');
// const laboAuthMetalSearchPage = () => import('./pages/labo/register/MetalSearch');
// const laboAuthMetalSettingPage = () => import('./pages/labo/register/MetalSetting');
// const laboMetalListPage = () => import('./pages/labo/register/MetalList');
const laboAuthClinicSearchPage = () => import('./pages/labo/register/ClinicSearch');
const laboAuthClinicAddPage = () => import('./pages/labo/register/ClinicAdd');
// const laboStockListPage = () => import('./pages/labo/register/StockList');
// const laboStockAddPage = () => import('./pages/labo/register/StockAdd');
const laboComfirmPage = () => import('./pages/labo/register/Comfirm');

const laboMyPage = () => import('./pages/labo/MyPage');
const laboLossPage = () => import('./pages/labo/LaboLoss');


const laboCalendarPage = () => import('./pages/labo/newCalendar/Calendar');
const laboCalendarDayPage = () => import('./pages/labo/newCalendar/CalendarDay');

const laboMetalPage = () => import('./pages/labo/metal/Metal');
const laboMetalSettingPage = () => import('./pages/labo/metal/MetalSetting');
const laboMetalSearchPage = () => import('./pages/labo/metal/MetalSearch');
const laboMetalContactPage = () => import('./pages/labo/metal/MetalContact');

const laboBasePage = () => import('./pages/labo/base/LaboBase');
const laboBaseInfoPage = () => import('./pages/labo/base/LaboBaseInfo');

const laboStockPage = () => import('./pages/labo/stock/Stock');
const laboStockRegistPage = () => import('./pages/labo/stock/StockRegist');
const laboStockCompletePage = () => import('./pages/labo/stock/StockComplete');

const laboClinicPage = () => import('./pages/labo/partner/Clinic');
const laboClinicInfoPage = () => import('./pages/labo/partner/ClinicInfo');
const laboClinicSearchPage = () => import('./pages/labo/partner/ClinicSearch');
const laboClinicAddPage = () => import('./pages/labo/partner/ClinicAdd');
const laboClinicSettingPage = () => import('./pages/labo/partner/ClinicSetting');

const laboProfilePage = () => import('./pages/labo/Profile');
const laboCompletePage = () => import('./pages/labo/Complete');

const laboAccountPage = () => import('./pages/labo/Account');
const laboAccountEditPage = () => import('./pages/labo/AccountEdit');

const laboRegisterCompletePage = () => import('./pages/labo/register/RegisterComplete');

const laboInventoryPage = () => import('./pages/labo/inventory/Index');

const forgetPass= () => import('./pages/auth/ForgetPass');
const forgetPassConfirm= () => import('./pages/auth/ForgetPassConfirm');
const resetPass = () => import('./pages/auth/ResetPass');
const resetComplete = () => import('./pages/auth/ResetComplete');

const verifyCard= () => import('./pages/auth/VerifyCard');
const verifyCardComplete = () => import('./pages/auth/ChangeCardComplete');
const editCard = () => import('./pages/auth/EditCard');
const laboWithDrawPage = () => import('./pages/labo/WithDraw');
const laboWithDrawCompletePage  = () => import('./pages/labo/WithDrawComplete');

const pageNotFound = () => import('./pages/PageNotFound');

Vue.use(VueRouter)

var clinic_path = "/clinic"
var labo_path = "/labo"

import authClinic from './middleware/authClinic';
import authClinicAdmin from './middleware/authClinicAdmin';
import authLabo from './middleware/authLabo';
import authLaboAdmin from './middleware/authLaboAdmin';
import authTokenLabo from './middleware/authTokenLabo';
import authTokenClinic from './middleware/authTokenClinic';

const router = new VueRouter({
    mode: 'history',
    routes: [
        {
            path: "/",
            component: () => import('./layouts/Home'),
        },

        {
            path: clinic_path,
            component: () => import('./layouts/Clinic'),
            children: [
                {
                    path: clinic_path + '/login',
                    name: 'clinic-login',
                    component: clinicLoginPage,
                    meta: {
                        heading_title: "医院WEBログイン",
                        showBtnMyPage: "none",
                        title: "医院WEBログイン | Evi計り SMART AI",
                        showMenu: false,
                    }
                },
                {
                    path: clinic_path + "/register",
                    name: 'clinic-register',
                    component: clinicRegisterPage,
                    meta: {
                        heading_title: "新規登録",
                        showBtnMyPage: "none",
                        title: "新規登録 | Evi計り SMART AI",
                        showMenu: false,
                    }
                },
                {
                    path: clinic_path + "/clinic-search",
                    name: 'clinic-search',
                    component: clinicSearchPage,
                    meta: {
                        heading_title: "医院検索",
                        showPass: "none",
                        title: "医院新規登録 - 医院検索 | Evi計り SMART AI",
                        showMenu: false,
                    }
                },
                {
                    path: clinic_path + "/clinic-add",
                    name: 'clinic-add',
                    component: clinicAddPage,
                    meta: {
                        heading_title: "医院新規登録",
                        showPass: "none",
                        title: "医院新規登録 - 医院情報編集 | Evi計り SMART AI",
                        showMenu: false,
                    }
                },
                {
                    path: clinic_path + "/clinic-comfirm",
                    name: 'clinic-comfirm',
                    component: clinicComfirmPage,
                    meta: {
                        heading_title: "医院新規登録確認",
                        showPass: "none",
                        title: "医院新規登録 - 登録確認 | Evi計り SMART AI",
                        showMenu: false,
                    }
                },
                {
                    path: clinic_path + "/clinic-complete",
                    name: 'clinic-register-complete',
                    component: clinicRegisterCompletePage,
                    meta: {
                        heading_title: "医院情報登録完了",
                        showPass: "none",
                        title: "医院新規登録 - 完了 | Evi計り SMART AI",
                        showMenu: false,
                        showBtnMyPage: true,
                        register_complete: true
                    }
                },
                {
                    path: clinic_path,
                    name: 'clinic-mypage',
                    component: clinicMyPage,
                    meta: {
                        heading_title: "医院WEBマイページ",
                        middleware: [authClinic],
                        showBtnMyPage: "none",
                        showMenu: true,
                    }
                },
                {
                    path: clinic_path + "/profile",
                    name: 'clinic-profile',
                    component: clinicProfilePage,
                    meta: {
                        heading_title: "医院情報確認・変更",
                        middleware: [authClinicAdmin],
                        title: "医院情報登録 | Evi計り SMART AI",
                        showMenu: true,
                        back_url: 'clinic-mypage'
                    }
                },
                {
                    path: clinic_path + '/profile-complete',
                    name: 'clinic-profile-complete',
                    component: clinicProfileCompletePage,
                    meta: {
                        heading_title: "医院情報登録完了",
                        middleware: [authClinicAdmin],
                        title: "医院情報登録完了 | Evi計り SMART AI",
                        showMenu: true,
                        back_url: 'clinic-profile'
                    }
                },
                {
                    path: clinic_path + '/stock',
                    name: 'clinic-stock',
                    component: clinicStockPage,
                    meta: {
                        heading_title: "在庫管理",
                        middleware: [authClinic],
                        title: "在庫管理 | Evi計り SMART AI",
                        showMenu: true,
                        back_url: 'clinic-mypage'
                    }
                },
                {
                    path: clinic_path + '/stock-history',
                    name: 'clinic-stock-history',
                    component: clinicStockHistoryPage,
                    meta: {
                        heading_title: "過去の預け量と納品量の検索",
                        middleware: [authClinic],
                        title: "過去の預け量と納品量の検索 | Evi計り SMART AI",
                        showMenu: true,
                        back_url: 'clinic-stock'
                    }
                },
                {
                    path: clinic_path + '/calendar',
                    name: 'clinic-calendar',
                    component: clinicCalendarPage,
                    meta: {
                        heading_title: "エビデンスカレンダー",
                        middleware: [authClinic],
                        title: "エビデンスカレンダー | Evi計り SMART AI",
                        showMenu: true,
                        back_url: 'clinic-mypage'
                    }
                },
                {
                    path: clinic_path + '/calendar-day',
                    name: 'clinic-calendar-day',
                    component: clinicCalendarDayPage,
                    meta: {
                        heading_title: "エビデンスカレンダー",
                        middleware: [authClinic],
                        title: "エビデンスカレンダー | Evi計り SMART AI",
                        showMenu: true,
                        back_url: 'clinic-calendar'
                    }
                },
                {
                    path: clinic_path + '/clinic-lab',
                    name: 'clinic-lab',
                    component: clinicLabPage,
                    meta: {
                        heading_title: "取引先技工所一覧",
                        title: '取引先技工所一覧 | Evi計り SMART AI',
                        middleware: [authClinic],
                        showMenu: true,
                        back_url: 'clinic-mypage'
                    }
                },
                {
                    path: clinic_path + '/clinic-lab-info',
                    name: 'clinic-lab-info',
                    component: clinicLabInfoPage,
                    meta: {
                        heading_title: "取引先技工所情報",
                        title: '取引先技工所情報 | Evi計り SMART AI',
                        middleware: [authClinic],
                        showMenu: true,
                        back_url: 'clinic-lab'
                    }
                },
                {
                    path: clinic_path + '/clinic-lab-search',
                    name: 'clinic-lab-search',
                    component: clinicLabSearchPage,
                    meta: {
                        heading_title: "技工所検索",
                        title: '技工所検索 | Evi計り SMART AI',
                        middleware: [authClinic],
                        showMenu: true,
                        back_url: 'clinic-lab'
                    }
                },
                {
                    path: clinic_path + '/clinic-lab-add',
                    name: 'clinic-lab-add',
                    component: clinicLabAddPage,
                    meta: {
                        heading_title: "取引先技工所登録",
                        title: '技工所登録 | Evi計り SMART AI',
                        middleware: [authClinic],
                        showMenu: true,
                        back_url: 'clinic-lab-search'
                    }
                },

                {
                    path: clinic_path + '/clinic-account',
                    name: 'clinic-account',
                    component: clinicAccountPage,
                    meta: {
                        heading_title: "アカウント登録・一覧",
                        title: 'アカウント一覧 | Evi計り SMART AI',
                        middleware: [authClinicAdmin],
                        showMenu: true,
                        back_url: "clinic-mypage"
                    }
                },
                {
                    path: clinic_path + '/clinic-account/edit/:id',
                    name: 'clinic-account-edit',
                    component: clinicAccountEditPage,
                    meta: {
                        heading_title: "アカウント詳細",
                        title: 'アカウント詳細 | Evi計り SMART AI',
                        middleware: [authClinic],
                        showMenu: true,
                        back_url: 'clinic-account'
                    }
                },
                {
                    path: clinic_path + '/clinic-account/add',
                    name: 'clinic-account-add',
                    component: clinicAccountEditPage,
                    meta: {
                        heading_title: "アカウント追加登録",
                        title: 'アカウント追加登録 | Evi計り SMART AI',
                        middleware: [authClinicAdmin],
                        showMenu: true,
                        back_url: 'clinic-account'
                    }
                },
                {
                    path: clinic_path + '/clinic-metal',
                    name: 'clinic-metal',
                    component: clinicMetalPage,
                    meta: {
                        heading_title: "メタル一覧",
                        title: 'メタル一覧 | Evi計り SMART AI',
                        middleware: [authClinic],
                        showMenu: true,
                        back_url: 'clinic-mypage'
                    }
                },
                {
                    path: clinic_path + '/clinic-metal/info/:metal_abbr/:metal_name',
                    name: 'clinic-metal-info',
                    component: clinicMetalInfoPage,
                    meta: {
                        heading_title: "メタル詳細",
                        title: 'メタル詳細 | Evi計り SMART AI',
                        middleware: [authClinic],
                        showMenu: true,
                        back_url: 'clinic-metal'
                    }
                },
                {
                    path: clinic_path + '/clinic-metal/request',
                    name: 'clinic-metal-request',
                    component: clinicMetalRequestPage,
                    meta: {
                        heading_title: "メタル登録問合せ",
                        title: 'メタル登録問合せ | Evi計り SMART AI',
                        middleware: [authClinic],
                        showMenu: true,
                        back_url: 'clinic-metal'
                    }
                },
                {
                    path: clinic_path + '/clinic-metal/complete',
                    name: 'clinic-metal-complete',
                    component: clinicMetalCompletePage,
                    meta: {
                        heading_title: "メタル登録問合せ完了",
                        title: '問い合わせ完了画面 | Evi計り SMART AI',
                        middleware: [authClinic],
                        back_url: 'clinic-metal',
                        showMenu: true,
                    }
                },


                {
                    path: clinic_path + '/complete',
                    name: 'clinic-complete',
                    component: clinicCompletePage,
                    meta: {
                        heading_title: "メタル登録問合せ完了",
                        title: 'メタル登録問合せ完了 | Evi計り SMART AI',
                        not_override_header: true,
                        showMenu: true,
                    }
                },
                
                {
                    path: clinic_path + '/reset-password',
                    name: 'clinic-reset-password',
                    component: forgetPass,
                    meta: {
                        heading_title: "パスワードを忘れた場合",
                        showPass: "none",
                        title: "パスワードを忘れた場合 | Evi計り SMART AI",
                        back_url: 'clinic-mypage',
                    }
                },
                {
                    path: clinic_path + '/reset-password-confirm',
                    name: 'clinic-reset-password-confirm',
                    component: forgetPassConfirm,
                    meta: {
                        heading_title: "リセットURL送信完了",
                        showBtnMyPage: "none",
                        showPass: "none",
                        title: "リセットURL送信完了 | Evi計り SMART AI"
                    }
                },
                {
                    path: clinic_path + '/forget-user',
                    name: 'clinic-forget-user',
                    component: resetPass,
                    meta: {
                        heading_title: "パスワード再設定",
                        showBtnMyPage: "none",
                        showPass: "none",
                        title: "パスワード再設定 | Evi計り SMART AI"
                    }
                },
                {
                    path: '/reset-complete',
                    name: 'reset-complete',
                    component: resetComplete,
                    meta: {
                        heading_title: "パスワード再設定完了",
                        showBtnMyPage: "none",
                        showPass: "none",
                        title: "パスワード再設定完了 | Evi計り SMART AI"
                    }
                },
                {
                    path: clinic_path + '/verify-card',
                    name: 'clinic-verify-card',
                    component: verifyCard,
                    meta: {
                        heading_title: "カード情報入力",
                        showPass: "none",
                        title: "カード情報入力 | Evi計り SMART AI",
                        showMenu: false,
                        isClinic: true,
                        back_url: 'clinic-login',
                        back_btn_text: 'カード情報入力:',
                        middleware: [authTokenClinic],
                    }
                },
                {
                    path: clinic_path + '/edit-card',
                    name: 'clinic-edit-card',
                    component: editCard,
                    meta: {
                        heading_title: "カード情報変更",
                        showPass: "none",
                        title: "カード情報変更 | Evi計り SMART AI",
                        showMenu: true,
                        back_url: 'clinic-mypage',
                        isClinic: true,
                    }
                },
                {
                    path:  clinic_path + '/withdraw',
                    name: 'clinic-withdraw',
                    component: clinicWithDrawPage,
                    meta: {
                        heading_title: "医院情報 - 退会する",
                        showBtnMyPage: "none",
                        showPass: "none",
                        title: "退会 | Evi計り SMART AI",
                        middleware: [authClinicAdmin],
                    },
                },
                {
                    path:  clinic_path + '/withdraw-success',
                    name: 'clinic-withdraw-success',
                    component: clinicWithDrawCompletePage,
                    meta: {
                        heading_title: "退会フローが完了しました。 - 退会する",
                        title: "退会 | Evi計り SMART AI",
                        showMenu: true,
                        middleware: [authClinicAdmin],
                        back_url: 'clinic-login'
                    },
                },
                {
                    path: clinic_path + '/recover',
                    name: 'clinic-recover',
                    component: clinicRecoverPage,
                    meta: {
                        heading_title: "アカウントの復元",
                        showMenu: true,
                        showBtnMyPage: 'アカウントの復元'
                    }
                },
                {
                    path: clinic_path + '/recover-confirm',
                    name: 'clinic-recover-confirm',
                    component: clinicRecoverConfirmPage,
                    meta: {
                        heading_title: "アカウントの復元",
                        showMenu: true,
                        showBtnMyPage: false,
                        back_url: 'clinic-login'
                    }
                },
                {
                    path: clinic_path + '/recover-waiting',
                    name: 'clinic-recover-waiting',
                    component: clinicRecoverWaitingPage,
                    meta: {
                        heading_title: "アカウントの復元",
                        showMenu: true,
                        title: 'アカウントの復元',
                        showBtnMyPage: true
                    }
                },
                {
                    path: clinic_path + '/recover-complete',
                    name: 'clinic-recover-complete',
                    component: clinicRecoverCompletePage,
                    meta: {
                        heading_title: "アカウントの復元",
                        showMenu: true,
                        title: 'アカウントの復元',
                        showBtnMyPage: true
                    }
                },
            ]
        },

        {
            path: labo_path,
            component: () => import('./layouts/Labo'),
            children: [
                {
                    path: labo_path + '/login',
                    name: 'labo-login',
                    component: laboLoginPage,
                    meta: {
                        heading_title: "技工所WEBログイン",
                        showMenu: false,
                        hideFooter: true,
                        title: "技工所WEBログイン | Evi計り SMART AI"
                    }
                },

                {
                    path: labo_path + '/register',
                    name: 'labo-register',
                    component: laboRegisterPage,
                    meta: {
                        heading_title: "新規登録",
                        showMenu: false,
                        hideFooter: true,
                        title: "新規登録 | Evi計り SMART AI"
                    }
                },
                {
                    path: labo_path + '/labo-search',
                    name: 'labo-search',
                    component: laboSearchPage,
                    meta: {
                        heading_title: "技工所検索",
                        showMenu: false,
                        title: '技工所新規登録 - 技工所検索 | Evi計り SMART AI',
                        back_url: 'labo-register'
                    }
                },
                {
                    path: labo_path + '/labo-add',
                    name: 'labo-add',
                    component: laboAddPage,
                    meta: {
                        heading_title: "技工所新規登録",
                        showMenu: false,
                        title: '技工所新規登録 - 技工所情報確認 | Evi計り SMART AI',
                        back_url: 'labo-search'
                    }
                },
                // {
                //     path: labo_path + '/test',
                //     name: 'labo-add-test',
                //     component: laboAddTestPage,
                //     meta: {
                //         heading_title: "技工所新規登録",
                //         showMenu: false,
                //         title: '技工所新規登録 - 技工所情報確認 | Evi計り SMART AI',
                //         back_url: 'labo-search'
                //     }
                // },
                
                {
                    path: labo_path + '/auth/clinic-search',
                    name: 'labo-auth-clinic-search',
                    component: laboAuthClinicSearchPage,
                    meta: {
                        heading_title: "取引先医院検索",
                        showMenu: false,
                        title: "技工所新規登録 - 取引先医院検索 | Evi計り SMART AI",
                        back_url: 'labo-add'
                    }
                },
                {
                    path: labo_path + '/auth/clinic-add',
                    name: 'labo-auth-clinic-add',
                    component: laboAuthClinicAddPage,
                    meta: {
                        heading_title: "医院新規登録",
                        showMenu: false,
                        title: "技工所新規登録 - 医院詳細 | Evi計り SMART AI",
                        back_url: 'labo-auth-clinic-search'
                    }
                },
                // {
                //     path: labo_path + '/auth/metal-search',
                //     name: 'labo-auth-metal-search',
                //     component: laboAuthMetalSearchPage,
                //     meta: {
                //         heading_title: "取り扱いメタルの登録",
                //         showMenu: false,
                //         title: "取り扱いメタルの登録 | Evi計り SMART AI",
                //         back_url: 'labo-auth-clinic-add'
                //     }
                // },
                // {
                //     path: labo_path + '/auth/metal-setting',
                //     name: 'auth-labo-metal-setting',
                //     component: laboAuthMetalSettingPage,
                //     meta: {
                //         heading_title: "取扱メタル割増率",
                //         showMenu: false,
                //         title: "取り扱いメタル割増率設定 | Evi計り SMART AI",
                //         back_url: 'labo-auth-metal-search'
                //     }
                // },
                // {
                //     path: labo_path + '/auth/metal-list',
                //     name: 'labo-metal-list',
                //     component: laboMetalListPage,
                //     meta: {
                //         heading_title: "取り扱いメタル一覧",
                //         showMenu: false,
                //         title: "取り扱いメタル一覧 | Evi計り SMART AI",
                //         back_url: 'labo-auth-metal-search'
                //     }
                // },
                // {
                //     path: labo_path + '/auth/stock-list',
                //     name: 'labo-stock-list',
                //     component: laboStockListPage,
                //     meta: {
                //         heading_title: "在庫登録",
                //         showMenu: false,
                //         title: "在庫登録| Evi計り SMART AI",
                //         back_url: 'labo-metal-list'
                //     }
                // },
                // {
                //     path: labo_path + '/auth/stock-add',
                //     name: 'labo-stock-add',
                //     component: laboStockAddPage,
                //     meta: {
                //         heading_title: "在庫登録",
                //         showMenu: false,
                //         title: "在庫登録| Evi計り SMART AI",
                //         back_url: 'labo-stock-list'
                //     }
                // },
                {
                    path: labo_path + '/auth/confirm',
                    name: 'labo-comfirm',
                    component: laboComfirmPage,
                    meta: {
                        heading_title: "技工所新規登録確認",
                        showMenu: false,
                        title: "技工所新規登録 - 登録確認 | Evi計り SMART AI"
                    }
                },
                {
                    path: labo_path + '/verify-card',
                    name: 'labo-verify-card',
                    component: verifyCard,
                    meta: {
                        heading_title: "カード情報入力",
                        showBtnMyPage: "none",
                        showPass: "none",
                        title: "カード情報入力 | Evi計り SMART AI",
                        showMenu: false,
                        back_url: 'labo-login',
                        back_btn_text: 'ログイン画面へ',
                        middleware: [authTokenLabo],
                    }
                },
                {
                    path: labo_path + '/add-card',
                    name: 'labo-add-card',
                    component: verifyCard,
                    meta: {
                        heading_title: "カード情報入力",
                        showBtnMyPage: "none",
                        showPass: "none",
                        title: "カード情報入力 | Evi計り SMART AI",
                        showMenu: false,
                        back_url: 'labo-login'
                    }
                },
                {
                    path: labo_path + '/verify-card-complete',
                    name: 'verify-card-complete',
                    component: verifyCardComplete,
                    meta: {
                        heading_title: "お支払い方法の登録が完了",
                        showBtnMyPage: "none",
                        showPass: "none",
                        title: "お支払い方法の登録が完了 | Evi計り SMART AI",
                        hideFooter: true,
                    }
                },
                {
                    path: labo_path + '/auth/complete',
                    name: 'labo-register-complete',
                    component: laboRegisterCompletePage,
                    meta: {
                        heading_title: "技工所登録完了",
                        showMenu: false,
                        title: "技工所新規登録 - 完了 | Evi計り SMART AI",
                        back_url: 'labo-mypage',
                        hideFooter: true,
                        register_complete: true
                    }
                },
                {
                    path: labo_path,
                    name: 'labo-mypage',
                    component: laboMyPage,
                    meta: {
                        heading_title: "技工所WEBマイページ",
                        middleware: [authLabo],
                        showMenu: true,
                        title: '技工所WEBマイページ | Evi計り SMART AI',
                        hideFooter: true
                    }
                },
                {
                    path: labo_path + '/labo-loss',
                    name: 'labo-loss',
                    component: laboLossPage,
                    meta: {
                        heading_title: "技工士別ロス率一覧",
                        middleware: [authLabo],
                        showMenu: true,
                        title: "技工士別ロス率一覧 | Evi計り SMART AI",
                        back_url: 'labo-mypage'
                    }
                },
                {
                    path: labo_path + '/calendar',
                    name: 'labo-calendar',
                    component: laboCalendarPage,
                    meta: {
                        heading_title: "エビデンスカレンダー",
                        middleware: [authLabo],
                        showMenu: true,
                        title: "エビデンスカレンダー | Evi計り SMART AI",
                        back_url: 'labo-mypage'
                    }
                },
                {
                    path: labo_path + '/calendar-day',
                    name: 'labo-calendar-day',
                    component: laboCalendarDayPage,
                    meta: {
                        heading_title: "エビデンスカレンダー",
                        middleware: [authLabo],
                        showMenu: true,
                        title: "エビデンスカレンダー | Evi計り SMART AI",
                        hideFooter: true
                    }
                },

                {
                    path: labo_path + '/metal',
                    name: 'labo-metal',
                    component: laboMetalPage,
                    meta: {
                        heading_title: "取り扱いメタル登録・一覧",
                        middleware: [authLabo],
                        showMenu: true,
                        title: '取り扱いメタル登録・一覧 | Evi計り SMART AI',
                        back_url: 'labo-mypage'
                    }
                },
                {
                    path: labo_path + '/metal/setting',
                    name: 'labo-metal-setting',
                    component: laboMetalSettingPage,
                    meta: {
                        heading_title: "取扱メタル　割増率",
                        middleware: [authLabo],
                        showMenu: true,
                        title: 'メタル割増率設定／削除 | Evi計り SMART AI',
                        back_url: 'labo-metal'
                    }
                },
                {
                    path: labo_path + '/metal/search',
                    name: 'labo-metal-search',
                    component: laboMetalSearchPage,
                    meta: {
                        heading_title: "メタル検索",
                        middleware: [authLabo],
                        showMenu: true,
                        title: 'メタル一覧 | Evi計り SMART AI',
                        back_url: 'labo-metal'
                    }
                },
                {
                    path: labo_path + '/metal/contact',
                    name: 'labo-metal-contact',
                    component: laboMetalContactPage,
                    meta: {
                        heading_title: "メタル登録問合せ",
                        middleware: [authLabo],
                        showMenu: true,
                        title: 'メタル問合せ | Evi計り SMART AI',
                        back_url: 'labo-metal-search'
                    }
                },

                {
                    path: labo_path + '/inventory',
                    name: 'labo-inventory',
                    component: laboInventoryPage,
                    meta: {
                        heading_title: "預りメタルの在庫表",
                        middleware: [authLabo],
                        showMenu: true,
                        title: '預りメタルの在庫表 | Evi計り SMART AI',
                        back_url: 'labo-mypage'
                    }
                },

                {
                    path: labo_path + '/clinic',
                    name: 'labo-clinic',
                    component: laboClinicPage,
                    meta: {
                        heading_title: "取引先医院登録・一覧",
                        middleware: [authLabo],
                        showMenu: true,
                        title: '取引先医院一覧 | Evi計り SMART AI',
                        back_url: 'labo-mypage'
                    }
                },
                {
                    path: labo_path + '/clinic/info',
                    name: 'labo-clinic-info',
                    component: laboClinicInfoPage,
                    meta: {
                        heading_title: "医院詳細",
                        middleware: [authLabo],
                        showMenu: true,
                        title: '医院詳細 | Evi計り SMART AI',
                        back_url: 'labo-clinic'
                    }
                },

                {
                    path: labo_path + '/clinic/search',
                    name: 'labo-clinic-search',
                    component: laboClinicSearchPage,
                    meta: {
                        heading_title: "取引先医院検索",
                        middleware: [authLabo],
                        showMenu: true,
                        title: '医院検索 | Evi計り SMART AI',
                        back_url: 'labo-clinic'
                    }
                },
                {
                    path: labo_path + '/clinic/add',
                    name: 'labo-clinic-add',
                    component: laboClinicAddPage,
                    meta: {
                        heading_title: "医院新規登録",
                        middleware: [authLabo],
                        showMenu: true,
                        title: '医院詳細 | Evi計り SMART AI',
                        back_url: 'labo-clinic-search'
                    }
                },
                {
                    path: labo_path + '/clinic/setting',
                    name: 'labo-clinic-setting',
                    component: laboClinicSettingPage,
                    meta: {
                        heading_title: "割増率設定",
                        middleware: [authLabo],
                        showMenu: true,
                        title: '割増率設定画面 | Evi計り SMART AI',
                        back_url: 'labo-clinic-info'
                    }
                },

                {
                    path: labo_path + '/stock',
                    name: 'labo-stock',
                    component: laboStockPage,
                    meta: {
                        heading_title: "初期在庫設定",
                        middleware: [authLaboAdmin],
                        showMenu: true,
                        title: '在庫初期設定 | Evi計り SMART AI',
                        back_url: 'labo-mypage'
                    }
                },
                {
                    path: labo_path + '/stock/regist',
                    name: 'labo-stock-regist',
                    component: laboStockRegistPage,
                    meta: {
                        heading_title: "初期在庫メタル登録",
                        middleware: [authLaboAdmin],
                        showMenu: true,
                        title: '初期在庫メタル登録 | Evi計り SMART AI',
                        back_url: 'labo-stock'
                    }
                },
                {
                    path: labo_path + '/stock/complele',
                    name: 'labo-stock-complele',
                    component: laboStockCompletePage,
                    meta: {
                        heading_title: "初期在庫メタル登録完了",
                        middleware: [authLaboAdmin],
                        showMenu: true,
                        title: '初期在庫メタル登録完了 | Evi計り SMART AI',
                        back_url: 'labo-stock'
                    }
                },
                {
                    path: labo_path + '/profile',
                    name: 'labo-profile',
                    component: laboProfilePage,
                    meta: {
                        heading_title: "技工所情報の確認・修正",
                        middleware: [authLaboAdmin],
                        showMenu: true,
                        title: '技工所情報登録 | Evi計り SMART AI',
                        back_url: 'labo-mypage'
                    }
                },
                {
                    path: labo_path + '/complete',
                    name: 'labo-complete',
                    component: laboCompletePage,
                    meta: {
                        heading_title: "技工所情報登録完了",
                        showMenu: true,
                        title: '技工所情報登録完了 | Evi計り SMART AI',
                        back_url: 'labo-mypage',
                        not_override_header: true
                    }
                },
                {
                    path: labo_path + '/labo-base',
                    name: 'labo-base',
                    component: laboBasePage,
                    meta: {
                        heading_title: "拠点登録・一覧",
                        showMenu: true,
                        title: '拠点一覧 | Evi計り SMART AI',
                        back_url: 'labo-mypage',
                        middleware: [authLaboAdmin],
                    }
                },
                {
                    path: labo_path + '/labo-base/info',
                    name: 'labo-base-info',
                    component: laboBaseInfoPage,
                    meta: {
                        heading_title: "拠点登録",
                        showMenu: true,
                        title: '拠点詳細登録 | Evi計り SMART AI',
                        middleware: [authLaboAdmin],
                    }
                },
                {
                    path: labo_path + '/labo-account',
                    name: 'labo-account',
                    component: laboAccountPage,
                    meta: {
                        heading_title: "技工士の登録・一覧",
                        showMenu: true,
                        title: 'アカウント一覧 | Evi計り SMART AI',
                        back_url: 'labo-mypage',
                        middleware: [authLaboAdmin],
                    }
                },
                {
                    path: labo_path + '/labo-account/edit',
                    name: 'labo-account-edit',
                    component: laboAccountEditPage,
                    meta: {
                        heading_title: "アカウント詳細",
                        showMenu: true,
                        title: 'アカウント編集 | Evi計り SMART AI',
                        middleware: [authLabo],
                    }
                },
                {
                    path: labo_path + '/labo-account/add',
                    name: 'labo-account-add',
                    component: laboAccountEditPage,
                    meta: {
                        heading_title: "アカウント追加登録",
                        showMenu: true,
                        title: 'アカウント追加登録 | Evi計り SMART AI',
                        middleware: [authLaboAdmin],
                    }
                },
                {
                    path: labo_path + '/edit-card',
                    name: 'labo-edit-card',
                    component: editCard,
                    meta: {
                        heading_title: "カード情報変更",
                        showBtnMyPage: "none",
                        showPass: "none",
                        title: "カード情報変更 | Evi計り SMART AI",
                        showMenu: true,
                    }
                },
                {
                    path: labo_path + '/reset-password',
                    name: 'labo-reset-password',
                    component: forgetPass,
                    meta: {
                        heading_title: "パスワードを忘れた場合",
                        showBtnMyPage: "none",
                        showPass: "none",
                        title: "パスワードを忘れた場合 | Evi計り SMART AI",
                        back_url: 'labo-mypage',
                    }
                },
                {
                    path: labo_path + '/reset-password-confirm',
                    name: 'labo-reset-password-confirm',
                    component: forgetPassConfirm,
                    meta: {
                        heading_title: "リセットURL送信完了",
                        showBtnMyPage: "none",
                        showPass: "none",
                        title: "リセットURL送信完了 | Evi計り SMART AI"
                    }
                },
                {
                    path:  '/lab/forget-user',
                    name: 'labo-forget-user',
                    component: resetPass,
                    meta: {
                        heading_title: "パスワード再設定",
                        showBtnMyPage: "none",
                        showPass: "none",
                        title: "パスワード再設定 | Evi計り SMART AI"
                    }
                },
                {
                    path:  '/labo/withdraw',
                    name: 'labo-withdraw',
                    component: laboWithDrawPage,
                    meta: {
                        heading_title: "技工所情報 - 退会する",
                        hideFooter: true,
                        showPass: "none",
                        title: "退会 | Evi計り SMART AI",
                        middleware: [authLaboAdmin],
                    }
                },
                {
                    path:  '/labo/withdraw-success',
                    name: 'labo-withdraw-success',
                    component: laboWithDrawCompletePage,
                    meta: {
                        heading_title: "退会フローが完了しました。",
                        showBtnMyPage: "none",
                        showPass: "none",
                        title: "退会フローが完了しました。 | Evi計り SMART AI",
                        middleware: [authLaboAdmin],
                        back_url: 'labo-login',
                        back_btn_text: 'ログイン画面へ'
                    }
                },
                {
                    path: labo_path + '/recover',
                    name: 'labo-recover',
                    component: laboRecoverPage,
                    meta: {
                        heading_title: "アカウントの復元",
                        showMenu: true,
                        title: 'アカウントの復元'
                    }
                },
                {
                    path: labo_path + '/recover-confirm',
                    name: 'labo-recover-confirm',
                    component: laboRecoverConfirmPage,
                    meta: {
                        heading_title: "アカウントの復元",
                        showMenu: true,
                        title: 'アカウントの復元'
                    }
                },
                {
                    path: labo_path + '/recover-waiting',
                    name: 'labo-recover-waiting',
                    component: laboRecoverWaitingPage,
                    meta: {
                        heading_title: "アカウントの復元",
                        showMenu: true,
                        title: 'アカウントの復元',
                        hideFooter: true
                    }
                },
                {
                    path: labo_path + '/recover-complete',
                    name: 'labo-recover-complete',
                    component: laboRecoverCompletePage,
                    meta: {
                        heading_title: "アカウントの復元",
                        showMenu: true,
                        title: 'アカウントの復元',
                        hideFooter: true,
                        back_url: 'labo-login'
                    }
                },
            ]
        },
        { path: "*", component: pageNotFound }
    ]
})
function nextFactory(context, middleware, index) {
    const subsequentMiddleware = middleware[index];
    if (!subsequentMiddleware) return context.next;
    return (...parameters) => {
        context.next(...parameters);
        const nextMiddleware = nextFactory(context, middleware, index + 1);
        subsequentMiddleware({ ...context, next: nextMiddleware });
    };
}


//Fix back browser button
// window.addEventListener('popstate', () => {
//     if(!localStorage.getItem('loaded'))
//         router.go(0);
//     localStorage.setItem('loaded', true);
// })

router.beforeEach((to, from, next) => {
    if (to.meta.middleware) {
        const middleware = Array.isArray(to.meta.middleware)
            ? to.meta.middleware
            : [to.meta.middleware];
        const context = {
            from,
            next,
            router,
            to,
        };
        const nextMiddleware = nextFactory(context, middleware, 1);
        return middleware[0]({ ...context, next: nextMiddleware });
    }
    return next();
});

const DEFAULT_TITLE = '医院WEBログイン | Evi計り SMART AI';
router.afterEach((to) => {
    Vue.nextTick(() => {
        if(!to.meta.not_override_header)
            document.title = to.meta.title || DEFAULT_TITLE;
    });
});

export default router;

